import { StaticImage } from 'gatsby-plugin-image'
import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Body1 from '../../tags/body1'
import H1 from '../../tags/h1'


export default function BreathingsTodos() {
  
  const {t} = useTranslation();
  return (
    <LayoutTodos>
      <VisionComponent text={t} />
      <TodosComponent text={t} />
    </LayoutTodos>
  )
}

const LayoutTodos = ({children}) => (
  <article className="w-full lg:max-w-7xl mx-auto box-border px-4 md:px-page-x-sm lg:px-page-x py-page-y flex flex-col items-center">
    <div className="w-full h-full md:flex md:flex-row flex-wrap justify-between">
      {children}
    </div>
  </article>
)

const VisionComponent = ({text}) => (
  <section className="md:max-w-[306px] lg:max-w-[388px] md:h-full flex-1 flex-col">
    <H1 className="font-metropolis">Breathings</H1>
    <Body1 className="mt-8 whitespace-pre-line">
      {text("main.todoDesc")}
    </Body1>
    <div className="w-full h-[272px] mt-[72px]">
      <StaticImage
        src="../../../images/support-breath.png"
        alt="Support Breath"
        className="w-full h-full"
      />
    </div>
  </section>
)

const TodosComponent = ({text}) => (
  <section className="lg:max-w-[310px] md:max-w-[225px] flex-1 lg:mt-[72px] md:mt-0 mt-[72px] flex-col justify-center space-y-[72px]">
    <TodoItem 
      title="What to do"
      content={text("main.whattodo")}
    />
    <TodoItem 
      title="How to do"
      content={text("main.howtodo")}
    />
    <TodoItem 
      title="Why to do"
      content={text("main.whytoit")}
    />
  </section>
)

const TodoItem = ({title="", content=""}) => (
  <div>
    <h4 className="h-6 mb-5 text-main text-base font-bold leading-4 whitespace-pre-line font-metropolis">{title}</h4>
    <Body1 className="mt-7">{content}</Body1>
  </div>
)