import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body1 from '../../tags/body1';


export const query = graphql`
query ImageQuery{
  allFile(filter: {absolutePath: {regex: "/images/main//"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`
export default function BreathingsSolutions() {

  const data = useStaticQuery(query);
  const {t} = useTranslation();
  const Images = data.allFile.nodes;
  
  return (
    <SolutionsLayout>
      <SolutionSection>
        <SolutionImage 
          source={Images[0]} 
          alt="호흡 건강" 
          imageClass="rounded-tr-3xl"
          align="justify-end md:justify-start"
        />
        <SolutoinTextGroup 
          title="BREATH HEALTH"
          description={t("main.health")} 
          align="items-end"
        />
        </SolutionSection>
      <SolutionSection reverse={true}>
        <SolutoinTextGroup 
          title="BREATH SOLUTION"
          align="md:justify-start"
          description={t("main.solution")} 
        />
        <SolutionImage 
          source={Images[1]} 
          alt="호흡 건강 솔루션" 
          imageClass="rounded-tl-3xl"
          align="justify-start md:justify-end"
        />
      </SolutionSection>
    </SolutionsLayout>
  )
}
const SolutionsLayout = ({children}) => (
  <article className="w-full lg:max-w-7xl mx-auto py-page-y flex flex-col px-4 md:px-page-x-sm lg:px-page-x-s space-y-[100px]">
    {children}
  </article>
)
const SolutionSection = ({reverse = false, children}) => (
  <section className={`flex flex-1 md:h-full md:justify-between ${reverse ? 'flex-col-reverse md:flex-row justify-start' : 'flex-row justify-end space-y-[72px] md:space-y-0'}`}>
    {children}
  </section>
)

const SolutionImage = ({source={}, alt="", align="", imageClass=""}) => {

  const image = getImage(source);
  return (
    <div className={`flex w-full md:max-w-[388px] h-[236px] ${align}`}>
      <GatsbyImage
        image={image}
        alt={alt}
        className={`h-full max-w-[306px] ${imageClass}`}
      />
    </div>
  )
}

function SolutoinTextGroup({title="", description, align=""}) {
  
  const divibeTitle = () => {
    return title.split(" ").map((txt, idx) => <span key={idx}>{txt}</span>).reduce((prev, curr) => [prev, <br key={'br'}/>, curr]);
  }
  const TitleGroup = divibeTitle();
  const onRight = align.endsWith("end");
  return (
    <div className={`w-full flex flex-col space-y-10 ${align} ${!onRight && "mt-[72px] md:mt-0"}`}>
      <div className="text-[44px] leading-[44px] font-bold text-gray-080 font-metropolis"><strong>{TitleGroup}</strong></div>
      <Body1 className={`${onRight ? 'text-right' : ''} break-words whitespace-pre-line`}>{description}</Body1>
    </div>
  )
}