import * as React from "react"
import MainArticle from "../components/MainArticle"
import BreathingsTodos from "../components/pages/main/breathingsTodos"
import Main from "../layout/main"
import BreathingsSolutions from "../components/pages/main/breathingsSolutions"
import BreathingsNewService from "../components/pages/main/beathingsNewService"

const IndexPage = (props) => {
  
  return (
    <Main title="메인 페이지" {...props}>
      <MainArticle />
      <BreathingsTodos />
      <BreathingsSolutions />
      {/* <BreathingsNewService /> */}
    </Main>
  )
}

export default IndexPage